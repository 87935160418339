import request from '@/request';

const part_url = "uas/admin/role/";
export default {
    /**
     * 获取所有可用角色
     */
    getRolesAll() {
        let url = part_url + "getAll";
        return request.get(url);
    },
    getUserRolesByName(userName){
        let url = part_url + 'getUserRolesByName';
        return request.get(url, {userName}, {});
    }
}