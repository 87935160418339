<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div class="tableList">
      <div class="search" v-if="!modal">
        <div class="search-body">
          <a-button style="float: right" @click="gotoList">返回</a-button>
        </div>
      </div>
      <div style="text-align: center; font-size: 40px">请假申请</div>
      <div class style="position: relative; text-align: center" :getContainer="handleScollMain">
        <a-form-model ref="form" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol"
          style="width: 70%; padding: 30px; margin: 0 20% 0 10%">
          <a-row>
            <a-col :span="12" style="text-align: left">
              <a-form-model-item label="请假类别" prop="category">
                <a-select v-model="form.category">
                  <a-select-option v-for="item in category" :value="item.itemValue" :key="item.itemValue">{{
                    item.itemName }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12" style="text-align: left">
              <a-form-model-item label="请假类型" prop="type">
                <a-select v-model="form.type">
                  <a-select-option v-for="item in type" :value="item.itemValue" :key="item.itemValue">{{ item.itemName
                    }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12" style="text-align: left">
              <a-form-model-item label="请假人" prop="applyUser">
                <a-input-search class="select_search" readOnly placeholder="请选择" v-model="form.applyUserName"
                  @click="onSearch" @search="onSearch" disabled="disabled" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12" style="text-align: left">
              <a-form-model-item label="部室" prop="org">
                <a-cascader :options="orgs" placeholder="请选择部室" @change="orgChanged" :fieldNames="{
                  label: 'name',
                  value: 'id',
                  children: 'children',
                }" v-model="form.orgId" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12" style="text-align: left">
              <a-form-model-item label="时间" prop="time">
                <a-range-picker v-model="form.time" :showTime="true" @change="timeChanged"
                  style="width: 100%"></a-range-picker>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12" style="text-align: left">
              <a-form-model-item label="请假天数" prop="days">
                <a-input placeholder="请输入请假天数" readOnly v-model="form.days"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="12" style="text-align: left">
              <a-form-model-item label="职务" prop="duties">
                <a-select v-model="form.duties" placeholder="请选择">
                  <template v-for="tag in roleAll">
                    <a-select-option :value="tag.name" :key="tag.id">
                      {{ tag.name }}
                    </a-select-option>
                  </template>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>

          <a-row>
            <a-col :span="24" style="text-align: left">
              <a-form-model-item :labelCol="{ span: 4 }" :wrapperCol="{ span: 20 }" label="请假事由" prop="description">
                <a-textarea placeholder="请输入请假事由" v-model="form.description" style="height: 100px"></a-textarea>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col>
              <a-button type="primary" v-if="form.status == 0 || form.status == 3" style="margin-right: 5px"
                @click="handleSubmit(0)">保存</a-button>
              <a-popconfirm v-if="form.status == 0 || form.status == 3" title="提交后数据不可更改，是否确认？"
                @confirm="handleSubmit(1)">
                <a-button type="danger">提交</a-button>
              </a-popconfirm>
              <a-button type="primary" v-if="form.processExecuteStatus && form.taskKey == '其他-审批'"
                style="margin-right: 5px" @click="showaudit()">审批
              </a-button>
              <a-button type="default" v-if="this.form.status == 2" @click="showWps" class="cancelBtn">打印</a-button>
              <a-button v-if="modal" @click="cancelClick">取消</a-button>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
    </div>
    <a-modal title="选择用户" v-model="selectuservisible" width="70%" :footer="false" destroyOnClose :maskClosable="false">
      <selectuser @callback="selectuserCallback" />
    </a-modal>
    <a-modal title="审批" v-model="auditModal.visible" width="40%" :footer="false" destroyOnClose :maskClosable="false">
      <audit :info="auditModal.info" @callback="auditCallBack" />
    </a-modal>
  </a-spin>
</template>
<script>
import orgApi from "@/api/org";
import dictionaryItemApi from "@/api/system/dictionaryItem";
import selectuser from "../components/searchuser.vue";
import leaveapplicationApi from "@/api/work/leaveapplication";
import pinyin from "js-pinyin";
import roleApi from "@/api/role";
var that;
export default {
  props: {
    info: {
      type: Object,
      default: () => { },
    },
    modal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      roleAll: [],
      orgs: [],
      form: {
        time: null,
        days: 0,
        status: 0,
      },

      rules: {
        applyUser: [
          {
            required: true,
            message: "请选择请假人",
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (this.form.applyUser == null || this.form.applyUser == "") {
                callback(new Error(""));
                return;
              }
              callback();
            },
          },
        ],
        org: [
          {
            required: true,
            message: "请选择部室",
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (this.form.orgId.length == 0) {
                callback(new Error(""));
                return;
              }
              callback();
            },
          },
        ],
        time: [
          {
            required: true,
            message: "请选择请假时间",
            trigger: "change",
          },
        ],
        days: [
          {
            required: true,
            message: "请输入请假天数",
            trigger: "blur",
          },
        ],
        category: [
          {
            required: true,
            message: "请选择请假类别",
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: "请选择请假类型",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            message: "请输入请假事由",
            trigger: "blur",
          },
        ],
      },
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      selectuservisible: false,
      isloading: false,
      loadingTip: "加载中...",
      category: [],
      type: [],
      zhiwu: [],
      auditModal: {
        info: null,
        visible: false,
      },
    };
  },
  mounted() {
    this.$nextTick(function () {
      if (this.info) {
        this.getData();
      } else {
        // var item = JSON.parse(localStorage.getItem("leaveapplicationinfo"));
        // var flag=false;
        // for(var key in item){
        //   if(key!='status'){
        //     flag=true;
        //     break;
        //   }
        // }
        // if(flag){
        //   this.form=item;
        //   if(this.form.startTime){
        //     this.form.startTime=this.$moment(this.form.startTime)
        //     this.form.endTime=this.$moment(this.form.endTime)
        //     this.form.time=[this.form.startTime,this.form.endTime]
        //   }
        //
        // }else{
        //   var params=this.$route.params;
        //   if(params && params.status!=null){
        //     this.form=params;
        //   }else{
        //     this.getLast();
        //   }
        // }
        this.createnew();
      }
      this.getOrgTree();
      this.getDic();
    });
  },
  watch: {
    info: {
      handler(newValue, oldValue) {
        if (newValue.id == null || newValue.id == 0)
          localStorage.setItem(
            "leaveapplicationinfo",
            JSON.stringify(newValue)
          );
      },
      deep: true,
    },
  },
  components: {
    selectuser,
  },
  methods: {
    createnew() {
      this.form = { status: 0 };
      // this.getOrgTree();
      // this.getDic();
      localStorage.removeItem("leaveapplicationinfo");
    },
    getLast() {
      leaveapplicationApi.getLastForUser().then((res) => {
        if (res.errorCode == this.$commons.RespCode.success) {
          res.data.orgId = res.data.orgPath;
          res.data.startTime = this.$moment(res.data.startTime, "YYYY-MM-DD");
          res.data.endTime = this.$moment(res.data.endTime, "YYYY-MM-DD");
          delete res.data.orgPath;
          this.form = res.data;
          this.form.time = [
            this.$moment(res.data.startTime, "YYYY-MM-DD"),
            this.$moment(res.data.endTime, "YYYY-MM-DD"),
          ];
        }
      });
    },
    showaudit() {
      this.auditModal.info = this.form;
      this.auditModal.visible = true;
    },
    auditCallBack(data) {
      this.auditModal.visible = false;
      this.$emit("callback", data != null);
    },
    getData() {
      this.loading = true;
      leaveapplicationApi.getData(this.info.id).then((res) => {
        this.loading = false;
        if (res.errorCode == this.$commons.RespCode.success) {
          res.data.orgId = res.data.orgPath;
          res.data.startTime = this.$moment(res.data.startTime, "YYYY-MM-DD HH:mm:ss");
          res.data.endTime = this.$moment(res.data.endTime, "YYYY-MM-DD HH:mm:ss");
          delete res.data.orgPath;
          this.form = res.data;
          this.form.time = [
            this.$moment(res.data.startTime, "YYYY-MM-DD HH:mm:ss"),
            this.$moment(res.data.endTime, "YYYY-MM-DD HH:mm:ss"),
          ];
          if (
            this.info &&
            this.info.taskId &&
            this.info.taskId != this.form.taskId
          ) {
            this.form.taskId = this.info.taskId;
          }
        }
      });
    },
    async getDic() {
      var d1 = dictionaryItemApi.getItemByDictionaryName("请假类型");
      var d2 = dictionaryItemApi.getItemByDictionaryName("请假类别");
      var d3 = dictionaryItemApi.getItemByDictionaryName("职务");
      var d1r = await d1;
      var d2r = await d2;
      var d3r = await d3;
      if (d1r.errorCode == this.$commons.RespCode.success) {
        this.type = d1r.data;
      }
      if (d2r.errorCode == this.$commons.RespCode.success) {
        this.category = d2r.data;
      }
      if (d3r.errorCode == this.$commons.RespCode.success) {
        this.zhiwu = d3r.data;
      }
    },
    gotoList() {
      this.$router.push({ path: "/business/leaveapplication" });
    },
    onSearch() {
      this.selectuservisible = true;
    },
    selectuserCallback(user) {
      this.form.applyUser = user.userName;
      this.form.applyUserName = user.realName;
      this.selectuservisible = false;
    },
    orgChanged(v) {
      this.form.orgId = v;
      this.$forceUpdate();
    },
    handleSubmit(status) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let formData = { ...this.form, status };
          formData.days = parseFloat(formData.days);
          formData.startTime = formData.startTime.format("YYYY-MM-DD HH:mm:ss");
          formData.endTime = formData.endTime.format("YYYY-MM-DD HH:mm:ss");
          formData.orgId = formData.orgId[formData.orgId.length - 1];
          delete formData.time;
          delete formData.auditRecords;
          this.loading = true;
          this.loadingTip = "保存中...";
          if (this.info) {
            formData.id = this.info.id;
          }
          //添加
          leaveapplicationApi
            .addOrUpdate(formData)
            .then((res) => {
              if (res.errorCode == this.$commons.RespCode.success) {
                this.$message.success(res.errorMsg);
                localStorage.removeItem("leaveapplicationinfo");
                if (this.modal) {
                  this.$emit("callback");
                } else {
                  this.gotoList();
                }
              } else {
                this.$message.error(res.errorMsg);
              }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    cancelClick() {
      this.$emit("callback", false);
    },
    timeChanged(times) {
      console.log(times);
      this.form.days = 0;
      if (times.length > 0) {
        this.form.startTime = times[0];
        this.form.endTime = times[1];
        var duration = this.$moment.duration(
          this.form.endTime - this.form.startTime
        );
        console.log(duration);

        let hours = duration.hours();
        let addDays = 1;
        if (hours > 0 && hours < 4) {
          addDays = 0.5;
        }
        this.form.days = duration.days() + addDays;
      }
    },
    getOrgTree() {
      orgApi.getTree(true, true).then((res) => {
        if (res.errorCode == this.$commons.RespCode.success) {
          this.orgs = res.data.tree;
          if (!this.info) {
            this.form.orgId = res.data.path;
            let tree = this.orgs;
            for (var i = 0; i < res.data.path.length; ++i) {
              var id = res.data.path[i];
              var find = tree.filter((n) => n.id == id);
              if (i == res.data.path.length - 1) {
                this.form.orgName = find[0].name;
              } else {
                tree = find[0].children;
              }
            }
            this.form.applyUserName = res.data.realName;
            this.form.applyUser = localStorage.getItem(
              this.$commons.User.userName
            );
            this.getUserRolesByName(this.form.applyUser);
            this.$forceUpdate();
          }
        }
      });
    },
    getUserRolesByName(userName) {
      roleApi.getUserRolesByName(userName).then((res) => {
        if (res.errorCode == "0000") {
          this.roleAll = res.data.filter((n) => n.name != "超级管理员");
          if (this.form.duties == "" || this.form.duties == null) {
            if (this.roleAll.length != null && this.roleAll.length > 0)
              this.form.duties = this.roleAll[0].name;
          }
        }
      });
    },
    error(msg) {
      this.$error({
        title: "提示",
        content: msg == undefined ? "服务器异常" : msg,
        okText: "返回",
        centered: true,
        onOk() {
          that.$emit("callbackMethod");
        },
      });
    },

    handleScollMain() {
      return document.querySelector(".ant-modal-wrap");
    },
    showWps() {
      let templateId = "qingjia";

      let innerData = Object.assign({}, this.form);
      delete innerData.auditRecords;
      delete innerData.processExecuteStatus;
      delete innerData.processId;
      delete innerData.processModelKey;
      delete innerData.processStatus;
      delete innerData.taskId;
      delete innerData.taskKey;
      delete innerData.taskRoles;
      delete innerData.taskUsers;
      delete innerData.status;
      delete innerData.creatorOrgId;

      innerData.createTime = this.$moment(
        innerData.createTime,
        "YYYY-MM-DD"
      ).format("YYYY年MM月DD日");
      innerData.time =
        innerData.startTime.format("YYYY年MM月DD日") +
        "至 " +
        innerData.endTime.format("YYYY年MM月DD日");

      this.form.auditRecords.forEach((a) => {
        let key =
          "taskKey." + pinyin.getFullChars(a.taskKey.replace(/\d+/g, ""));
        if (a.taskPrintKey != null) {
          key = "taskKey." + a.taskPrintKey;
        }
        if (innerData[key] == null) {
          innerData[key] = [];
        }
        let task = {
          auditRemark: a.auditRemark,
          pic: a.auditUserSignPicture,
          name: a.auditUserName,
          time: this.$moment(a.time, "YYYY-MM-DD").format("YYYY年MM月DD日"),
        };
        innerData[key].push(task);
      });

      orgApi.getSignPictures([this.form.applyUser]).then((res) => {
        let ottask = {
          auditRemark: this.form.description,
          pic: res[this.form.creator],
          name: this.form.applyUserName,
          time: this.$moment(this.form.createTime, "YYYY-MM-DD").format(
            "YYYY年MM月DD日"
          ),
        };
        innerData["taskKey.QingJiaRen"] = [];
        innerData["taskKey.QingJiaRen"].push(ottask);
        localStorage.setItem("innerData", JSON.stringify(innerData));
        localStorage.removeItem("publicFileId");

        const jump = this.$router.resolve({
          name: "wpsedit",
          query: {
            //要传的参数
            templateId: templateId,
            fileType: "public",
            openType: "create",
            fileName: "请假",
          },
        });

        window.open(jump.href, "_blank");
      });
    },
  },
};
</script>
<style></style>
